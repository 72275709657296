<template>
  <!-- 补充文件操作按钮组 -->
  <div>
    <el-upload
      class="upload-demo"
      action=""
      :http-request="function (url) {
          return Upload(url, scope.row, 3, scope.$index);
        }
      "
      :show-file-list="false"
      v-if="scope.row.documentId"
    >
      <icon-button
        :content="
          !scope.row.fileId || scope.row.fileId.toString() === '0'
            ? '上传'
            : '重新上传'
        "
        :icon="
          !scope.row.fileId || scope.row.fileId.toString() === '0'
            ? 'iconfont iconshangchuan'
            : 'iconfont iconzhongxinshangchuan'
        "
      />
    </el-upload>
    <icon-button
      content="预览"
      icon="iconfont iconyulan"
      @click="previewShow(scope.row)"
      v-if="scope.row.fileId && scope.row.fileId.toString() !== '0'"
    />
    <icon-button
      @click="deleteTemplate(scope.row, 3, scope.$index)"
      content="删除"
      icon="iconfont iconshanchu1"
    />
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton },
  props: {
    scope: {}
  },
  data () {
    return {}
  },

  methods: {
    // 预览
    previewShow (row) {
      this.$emit('previewShow', row)
    },
    // 文件上传
    Upload (param, row, type, index) {
      this.$emit('Upload', param, row, type, index)
    },
    // 文件删除
    deleteTemplate (row, type, index) {
      this.$emit('deleteTemplate', row, type, index)
    }
  }
}
</script>
